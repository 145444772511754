<template>
  <div class="site-creation-form">
    <header class="site-creation-form__header">
      <div class="site-creation-form__header__container">
        <figure>
          <img
            class="site-creation-form__header__logo"
            src="@/assets/svg/logo-solency.svg"
            alt=""
          />
        </figure>
        <div class="site-creation-form__header__percentage">
          <span class="site-creation-form__header__percentage__value">{{
            currentPercentage
          }}</span>
          <span class="site-creation-form__header__percentage__sign">%</span>
        </div>
      </div>
      <div
        class="site-creation-form__header__progress"
        :style="`width: ${currentPercentage}%`"
      ></div>
    </header>
    <div class="site-creation-form__body">
      <router-view
        @next-step="nextStep"
        @validate="isCurrentStepInvalid = $event"
      ></router-view>
    </div>
    <div v-show="shouldCtaBeDisplayed" class="site-creation-form__cta-container">
      <button
        :class="['button', { 'button--invalid': isCurrentStepInvalid }]"
        @click="nextStep"
      >
        Continuer
      </button>

      <a class="link link--margin-bottom" @click="previousStep">
        Retour à l'étape précédente
      </a>
    </div>
  </div>
</template>

<script>
import { api } from '@/lib/api';

export default {
  async created() {
    const idAuth = this.getId;
    alert(idAuth);
    const { data: user } = await api.get(`/users/${idAuth}`);

    this.user = user;
    this.updateUser(user);

    this.isLoading = false;
    if (user.site) {
      return this.$router.push({ name: 'Dashboard' });
    }
  },
  data() {
    return {
      totalSteps: 17,
      stepPercentages: [
        0,
        0,
        10,
        20,
        30,
        40,
        50,
        55,
        60,
        65,
        70,
        75,
        80,
        85,
        90,
        95,
        100,
      ],
      isCurrentStepInvalid: true,
    };
  },

  computed: {
    getId: 'auth/getId',
    currentStep() {
      return parseInt(this.$route.path.split('/')[2]);
    },

    shouldCtaBeDisplayed() {
      const stepsWithoutCta = [0, 15, 16];

      return stepsWithoutCta.indexOf(this.currentStep) === -1;
    },

    currentPercentage() {
      return this.stepPercentages[this.currentStep];
    },
  },

  methods: {
    nextStep() {
      if (this.isCurrentStepInvalid) {
        return;
      }

      this.$router.push(`/creation-site/${this.currentStep + 1}`);
    },

    previousStep() {
      this.$router.push(`/creation-site/${this.currentStep - 1}`);
    },
  },
};
</script>
